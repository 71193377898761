@keyframes scaleUp {
    0% {
        visibility: hidden;
        transform: scale(0.5);
    }
    100% {
        visibility: visible;
        transform: scale(1);
    }
}

.searchResults {
    text-align: left;
    position: absolute;
    top: calc(100% + var(--space-8));
    left: calc(-1 * var(--space-24));
    right: calc(-1 * var(--space-24));
    block-size: fit-content;
    background-color: var(--elevated-background);
    z-index: var(--z-index-popup);
    border-radius: var(--border-radius-xl);
    border: 1px solid var(--elevated-border);
    box-shadow: var(--shadow-elevated);
    overflow: hidden;
    animation: scaleUp var(--animation-duration-50) forwards var(--animation-ease-in-out);

    ul {
        border-radius: var(--border-radius-xl);
        max-block-size: 50vh;
        overflow-y: auto;
        padding: 6px;
    }

    li {
        padding: var(--space-8) var(--space-12);
        gap: var(--space-4);
        position: relative;
        display: flex;
        flex-direction: row;
        gap: var(--space-12);
        border-radius: var(--border-radius-xl);
        transition: background-color var(--animation-duration-50) var(--animation-ease-in-out);

        &:hover {
            background-color: var(--actionable-quaternary-background-onhover);
        }
    }

    @media (--screen-lt-md) {
        left: 0;
        right: 0;
    }
}

.icon {
    block-size: 21px;
    display: flex;
    align-items: center;
}

.templateDetails {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);

    > p {
        color: var(--display-onlight-secondary);
    }

    a {
        text-decoration: none;
        outline: none;

        &::after {
            content: '';
            border-radius: var(--border-radius-xl);
            position: absolute;
            inset: 0;
        }

        &:focus-visible {
            &::after {
                background-color: var(--actionable-quaternary-background-onhover);
            }
        }
    }
}
