.contextContainer {
    position: relative;
}

.contextMenu {
    position: absolute;
    inline-size: max-content;
    inset-block-start: calc(100% + var(--space-4));
    inset-inline-end: 0;
    z-index: var(--product-ui-z-index-popup);
    visibility: hidden;
}

.sequenceContainer :where([data-hide]) {
    display: none;
}

.sequenceContainer [data-productui='cursor'] {
    opacity: 0;
    inset: 50%;
}

.taskSectionOrdered {
    [data-productui-task='dentist'] {
        order: 1;
    }

    [data-productui-task='shortcut'] {
        order: 2;
    }

    [data-productui-task='yoga'] {
        order: 3;
    }

    [data-productui-task='bread'] {
        order: 4;
    }
}
