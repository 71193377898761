.integrationsContainer {
    margin-block-end: var(--space-);

    #grid-by-category {
        scroll-margin-block-start: var(--space-96);
    }

    .categoryHeader {
        display: flex;
        flex-direction: column;
        margin-block-end: var(--space-16);

        .description {
            color: var(--color-grey-07);
        }
    }

    .hairlineSearch {
        @media (--screen-gt-md) {
            display: none;
        }
    }

    .grid {
        display: grid;
        gap: var(--space-12);
        grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));

        img {
            object-fit: contain;
        }
    }

    .integrationGridHeader {
        display: flex;
        flex-direction: column;
        margin-block-end: var(--space-48);
        gap: var(--grid-header-space, var(--space-24));

        @media (--screen-gt-md) {
            --grid-header-space: var(--space-16);
        }
    }
}
