.copy {
    text-align: var(--text-align);

    --text-lock-up-button-alignment: flex-start;

    .preheader {
        color: var(--display-onlight-color-red);
    }

    .description {
        color: var(--display-onlight-secondary);
    }

    @media (--screen-lt-xlg) {
        --text-align: center;
        --text-lock-up-button-alignment: unset;
    }

    @media (--screen-lt-md) {
        --text-align: left;
        --text-lock-up-button-alignment: flex-start;
    }
}
