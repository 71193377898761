.prefooterBackground {
    background: linear-gradient(
        0,
        var(--background-color, --composition-background-yellow) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);

    @media (--screen-lt-md) {
        padding-block-start: var(--space-128);
    }
}

.hiddenTweets {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    inline-size: 100%;
    block-size: 100%;
    z-index: -1;
}

.leftColumn > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-32);

    block-size: 100%;
    max-inline-size: 400px;

    @media (--screen-lt-md) {
        text-align: center;
        --button-align: center;
    }

    a {
        align-self: var(--button-align, flex-start);
    }
}

.rightColumn {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tweetsContainer {
    /* determines the spacing between the scrolling tweet cards and the controls */
    block-size: calc(var(--tweets-container-height, 360px) + 70px);

    inline-size: var(--tweets-container-width, 500px);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-48);

    @media (--screen-lt-md) {
        --tweets-container-width: 100%;
    }

    .tweets {
        inline-size: 100%;
        block-size: calc(100% - 40px);
        position: relative;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            inline-size: 100%;
            block-size: calc(100% - var(--space-32));
        }

        > .dummyCard {
            left: var(--space-24);
            inline-size: calc(100% - var(--space-48));
            background-color: white;
            box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
            border-radius: var(--border-radius-base);
        }

        > .nextCard {
            left: var(--space-12);
            inline-size: calc(100% - var(--space-24));
            top: var(--space-16);

            .tweet {
                block-size: calc(100% - var(--space-32));
            }
        }

        > .currentCard {
            top: var(--space-32);
        }

        .tweet {
            > div {
                /* override the default tweet card margin */
                margin-block-end: var(--space-24);
            }
        }
    }
}
