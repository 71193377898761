.note {
    padding: var(--space-16);
    background-color: var(--box-bg, var(--box-default-background));
    border-radius: var(--border-radius-lg);

    &.warning {
        --box-bg: var(--box-warning-background);
    }

    p {
        color: var(--display-onlight-secondary);
    }
}
