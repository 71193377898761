.button {
    --product-ui-button-gap: 0;
    --product-ui-button-border: none;
    --product-ui-button-padding: 0 8px;
    --product-ui-button-border-radius: var(--border-radius-base);
    --product-ui-button-block-size: 36px;
    --product-ui-button-color: #fff;
    --product-ui-button-background-color: var(--productui-primary-theme-color);
    --product-ui-button-text-padding: 0 7px;
    --product-ui-button-icon-size: var(--space-24);

    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: var(--product-ui-button-gap);
    border: var(--product-ui-button-border);
    border-radius: var(--product-ui-button-border-radius);
    padding: var(--product-ui-button-padding);
    block-size: var(--product-ui-button-block-size);
    color: var(--product-ui-button-color);
    background-color: var(--product-ui-button-background-color);

    > div {
        padding: var(--product-ui-button-text-padding);
    }

    &.secondary {
        --product-ui-button-color: var(--display-onlight-primary);
        --product-ui-button-background-color: var(--actionable-secondary-background-idle);
    }

    &.quaternary {
        --product-ui-button-color: var(--display-onlight-secondary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-idle);
    }

    &.quinary {
        --product-ui-button-gap: 6px;
        --product-ui-button-padding: 0;
        --product-ui-button-block-size: 31px;
        --product-ui-button-text-padding: 0;
        --product-ui-button-color: var(--display-onlight-tertiary);
        --product-ui-button-background-color: var(--actionable-quinary-background-idle);
    }

    &.outline {
        --product-ui-button-padding: 0 var(--space-4);
        --product-ui-button-gap: 0;
        --product-ui-button-icon-size: 18px;
        --product-ui-button-border-radius: var(--border-radius-sm);
        --product-ui-button-color: var(--display-onlight-tertiary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-idle);
        --product-ui-button-border: 1px solid var(--ui-border);
        --product-ui-button-block-size: var(--tube-height-xs);
        --product-ui-button-text-padding: 0 var(--space-4);
    }
}

.hovered {
    --product-ui-button-background-color: var(--actionable-primary-background-onhover);

    &.secondary {
        --product-ui-button-background-color: var(--actionable-secondary-background-onhover);
    }

    &.quaternary,
    &.quinary,
    &.outline {
        --product-ui-button-color: var(--display-onlight-primary);
        --product-ui-button-background-color: var(--actionable-quaternary-background-onhover);
    }

    &.quinary {
        --product-ui-button-background-color: none;

        position: relative;
        z-index: 0;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -9px;
            right: -9px;
            bottom: 0;
            border-radius: var(--product-ui-button-border-radius);
            background-color: var(--actionable-quinary-background-onhover);
            z-index: -1;
        }
    }
}

.icon {
    flex-grow: 0;
    flex-shrink: 0;
}
