.sectionContainer {
    inline-size: 100%;

    a {
        align-self: flex-start;
    }
}

.twoColumn {
    align-items: center;
}

.imageWrapper {
    img {
        inline-size: 100%;
        block-size: auto;
    }
}

.videoWrapper {
    display: grid;
    place-items: center;
    inline-size: 100%;
    background-color: var(--ui-background);
    border-radius: var(--border-radius-2xl);
    overflow: hidden;

    & > *,
    &::after {
        grid-area: 1 / 1;
    }

    .videoThumbnail {
        inline-size: 100%;
        block-size: auto;
        object-fit: cover;
    }

    .video {
        inline-size: 100%;
        aspect-ratio: 16 / 9;
    }

    &::after {
        inline-size: calc(100% - 1px);
        block-size: calc(100% - 1px);
        content: '';
        box-shadow: var(--shadow-productui-frame-inset);
        border-radius: inherit;
        pointer-events: none;
    }
}

.overlayButton {
    box-shadow: var(--shadow-overlay);
    z-index: 20;
}

.right {
    inline-size: 100%;
    position: relative;
}
