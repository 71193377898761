.fullHeight {
    @media (--screen-gt-md) {
        block-size: 100vh;
        max-block-size: 40rem; /* 640px */
    }
}

.sceneContainer {
    --text-lock-up-button-alignment: flex-start;

    @media (--screen-lt-md) {
        display: flex;
        flex-direction: column;
        gap: var(--space-96);
    }
}
