.iconCard {
    display: flex;
    flex-direction: column;
    position: relative;
    block-size: 100%;
}

.link {
    outline: 0;

    &:hover {
        text-decoration: none;
    }

    &::after {
        position: absolute;
        content: '';
        inset: 0;
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 7.8125rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);

    img {
        object-fit: cover;
    }
}

.description {
    color: var(--display-onlight-secondary);
    flex: 1;
}

.lg .imageContainer {
    block-size: 11.5625rem;
}

.topRight {
    position: absolute;
    top: 8px;
    right: 8px;
}
