.redeemSection {
    .content {
        display: flex;
        flex-direction: column;
        gap: var(--space-24);
        padding: var(--card-padding, var(--space-48));

        border-radius: 10px;
        border: 1px solid var(--box-outline-border);
        background: var(--box-outline-background);

        @media (--screen-lt-md) {
            --card-padding: var(--space-48) var(--space-24);
        }
    }

    ol {
        display: flex;
        flex-direction: column;
        gap: var(--space-8);
    }

    li {
        list-style: decimal;
        margin-inline-start: var(--space-24);
        padding-inline-start: var(--space-4);

        &::marker {
            color: var(--display-onlight-color-red);
            font-weight: 600;
            margin-inline-end: var(--space-8);
        }
    }
}
