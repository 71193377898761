.listItem {
    display: flex;
    gap: var(--space-8);
    align-items: flex-start;

    svg {
        flex-shrink: 0;
        margin-block-start: 8px;
    }
}

.metaPill {
    margin-inline: 4px;
}

.list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: var(--space-4);

    p {
        line-height: var(--tube-height-sm);
    }
}
