.noResults {
    text-align: center;
}

.heading {
    text-align: left;

    @media (--screen-gt-md) {
        text-align: center;
    }
}

.noResultsHeader {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.hairline {
    @media (--screen-gt-md) {
        display: none;
    }
}
