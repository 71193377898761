.container {
    composes: fadeIn from 'styles/animations.module.css';
    animation-delay: 0.5s;

    &.withBorder {
        border-radius: var(--border-radius-lg);
        border: 1px solid var(--box-outline-border);
        background: var(--box-outline-background);
        padding: var(--space-16);
    }

    > p:not(:last-child) {
        margin-block-end: var(--space-16);
    }

    img {
        inline-size: 100%;
        max-inline-size: 416px;
        height: auto;
    }

    ul,
    ol {
        padding-block-start: 0;
        padding-block-end: var(--space-16);
    }

    li > p {
        display: inline;
    }

    li:before {
        content: '-';
        margin-inline-end: var(--space-4);
    }

    blockquote {
        margin-block-start: var(--space-16);
        padding: var(--space-8);
        background-color: var(--color-gray-04);
        border-radius: var(--border-radius-lg);
    }

    p:empty {
        display: none;
    }
}
