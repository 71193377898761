.skeletonText {
    display: grid;
    place-items: center;
    inline-size: var(--inlineSize);
    block-size: var(--blockSize);

    &::before,
    &::after {
        grid-row: 1;
        grid-column: 1;
    }

    &::before {
        display: block;
        content: ' ';
        inline-size: 100%;
        block-size: 100%;
        background-color: var(--bg-color, var(--product-ui-display-onlight-skeleton));
        border-radius: 3px;
    }

    &.onColor {
        &::before {
            --bg-color: var(--product-ui-display-oncolor-skeleton);
        }
    }
}

.striken {
    &::after {
        display: block;
        content: ' ';
        inline-size: calc(100% + 8px);
        block-size: 1px;
        background-color: var(--ui-border);
    }
}
