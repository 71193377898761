.suggestionsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid {
    composes: fadeIn from 'styles/animations.module.css';
    animation-delay: 0.5s;

    max-inline-size: 1088px;
    inline-size: 100%;

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, var(--card-width, 354px));
    gap: var(--space-12);

    @media (--screen-lt-md) {
        --card-width: 100%;
    }
}

.nextBtn {
    composes: fadeIn from 'styles/animations.module.css';
    margin-block-start: var(--space-64);
    animation-delay: 0.5s;
}
