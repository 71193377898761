.productFrame {
    --product-ui-font: var(--font-family-body);
    --major-base-font-family: var(--product-ui-font);
    --major-sm-font-family: var(--product-ui-font);
    --major-base-min-size: 1.4375; /* 23px */
    --major-base-max-size: 1.625; /* 26px */
    --body-sm-max-size: 0.96875; /* 15.5px */
    --font-weight-bold: 675;
    --font-weight-semibold: 600;
    /* This isn't used here, it was to force the css file to change because of https://github.com/Doist/Issues/issues/13543 */
    --force-rebuild: 1;

    position: relative;
    overflow: hidden;
    font-family: var(--product-ui-font);

    pointer-events: none;
    border-radius: var(--product-frame-border-radius, 15px);
}

.productUIFrame {
    position: relative;
    inline-size: var(--product-ui-inline-size, 36.875rem); /* 590px */
    block-size: var(--product-ui-block-size, 34.375rem); /* 550px */
    aspect-ratio: 590/472;
    backface-visibility: hidden;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: inherit;
        box-shadow: var(--shadow-productui-frame-inset);
        z-index: var(--product-ui-z-index-border);
    }
}

.productUIAppFrame {
    --product-frame-border-radius: 0;

    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    box-shadow: var(--shadow-elevated);
}

.lg {
    --product-ui-inline-size: 68rem;
    --product-ui-block-size: 34.375rem; /* 550px */
}

.baseRadius {
    --product-frame-border-radius: var(--border-radius-base);
}

@container style(--is-dev-env: true) {
    .productFrame {
        pointer-events: initial;

        &::after {
            pointer-events: initial;
        }
    }
}
