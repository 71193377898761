.productUISegmentedControl {
    display: flex;
    gap: var(--space-4);

    padding: var(--space-2);
    border-radius: var(--border-radius-lg);
    background: var(--ui-background);
}

.productUISegmentedControlItem {
    min-block-size: var(--tube-height-xs);
    border-radius: var(--border-radius-base);
    padding: 0.5px var(--space-16);
    display: flex;
    align-items: center;

    &.selected {
        background: var(--box-outline-background);
    }
}
