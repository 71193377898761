.statsBox {
    --img-width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);
    text-align: center;

    .statsBoxImgContainer {
        max-inline-size: var(--img-width);

        img {
            inline-size: 100%;
            block-size: auto;
            object-fit: contain;
        }
    }

    @media (--screen-lt-lg) {
        --img-width: 175px;
    }

    .statsBoxTextContainer {
        p:last-child {
            padding-top: var(--space-4);
            color: var(--display-onlight-secondary);
        }
    }
}
