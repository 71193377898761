.blockquote {
    position: relative;
    padding-inline-start: 0.875rem;
    color: var(--display-onlight-secondary);

    &::before {
        content: '';
        border-left: 2px solid var(--elevated-border);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
}
