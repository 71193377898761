.snippetForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    max-inline-size: 450px;
    margin: 0 auto;

    > div {
        inline-size: 100%;
    }
}

.nextBtn {
    composes: fadeIn from 'styles/animations.module.css';
    margin-block-start: var(--space-48);
    animation-delay: 0.5s;
}
