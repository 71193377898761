.sequenceContainer [data-productui='cursor'] {
    opacity: 0;
    inset: 50%;
}

.views {
    transform: scale(0.98);
    transform-origin: top left;
    block-size: 100%;
}

.contextMenu {
    transform: scale(0.86);
    transform-origin: top right;
}
