.sidebar {
    position: sticky;
    top: 6rem;
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    block-size: fit-content;

    @media (--screen-lt-lg) {
        display: none;
    }
}

.content {
    display: grid;
    grid-template-columns: 1fr minmax(0, 40.625rem) 1fr;

    > * {
        grid-column: 2;
    }

    figure,
    aside {
        grid-column: 1 / span 3;
        margin-inline: 0;
    }

    figure,
    img,
    video {
        max-inline-size: 100%;
        block-size: auto;
    }

    figure {
        text-align: center;
    }

    figcaption {
        color: var(--display-onlight-secondary);
    }
}

/* Spacing */
.content {
    > * + * {
        margin-block-start: var(--flow-space, var(--space-32));
    }

    p {
        --flow-space: var(--space-24);
    }

    h2 {
        --flow-space: var(--space-48);
    }

    figcaption {
        margin-block-start: var(--space-16);
    }

    hr {
        margin-block-start: var(--space-32); /* explicit, because of component issue */

        @media (--screen-lt-md) {
            margin-block-start: var(--space-24);
        }
    }

    iframe,
    span {
        --flow-space: 0;
    }
}

/** Styling */
.content {
    a:not([class]) {
        color: var(--display-onlight-color-blue);
    }

    table {
        inline-size: 100%;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid var(--ui-border);
        }

        td,
        th {
            padding: var(--space-16) var(--space-8);
        }

        thead th {
            color: var(--display-onlight-color-red);
            font-weight: 600;
        }
    }

    ul,
    ol {
        margin-block-start: var(--space-24);
        padding-inline-start: var(--space-32);
    }

    ul {
        list-style: disc;
    }

    ul ul {
        list-style: circle;
    }

    ul ul ul {
        list-style: square;
    }

    ol {
        list-style: decimal;
    }

    ::marker {
        color: var(--display-onlight-color-red);
        font-weight: 600;
    }

    ol > li {
        padding-left: 0.25em;
    }
    > ul li::marker {
        font-size: 1.5em;
    }

    li + li,
    ul ul,
    ul ol,
    ol ol,
    ol ul {
        margin-block-start: var(--space-8);
    }

    h1,
    h2,
    h3,
    h4 {
        scroll-margin-top: 90px;
    }
}

.highlight {
    position: relative;
    padding-block-start: var(--space-96);
    padding-block-end: var(--space-64);
    min-block-size: 18rem;

    img {
        --scale: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) scale(var(--scale));
        inline-size: 100%;
        block-size: auto;
        max-inline-size: 45.5rem;
        pointer-events: none;

        @media (--screen-lt-md) {
            --scale: 1.5;
        }
    }

    > * {
        position: relative;
        max-inline-size: 37.5rem;
        margin-inline: auto;
    }

    figcaption {
        color: var(--display-onlight-primary);
    }
}

.cta {
    display: flex;
    gap: var(--space-48);
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color, #fff6f0);

    @media (--screen-lt-md) {
        flex-direction: column-reverse;
        gap: var(--space-24);
        text-align: center;
    }
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.markdownQuote {
    position: relative;
    padding-inline-start: 0.875rem;

    &::before {
        content: '';
        border-left: 2px solid var(--elevated-border);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
}
