.main > div:first-child {
    display: flex;
    inline-size: 100%;
    border: var(--box-outline-border-size) solid var(--box-outline-border);
    border-radius: var(--border-radius-base);
    transition: block-size var(--animation-ease-base) var(--animation-duration-150);
    block-size: fit-content;
    overflow: hidden;
    margin-block-end: var(--space-64);
}
