.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (--screen-lt-md) {
        padding: 0;
    }
}

.header {
    max-inline-size: 650px;
    margin-block-end: var(--space-64);
    --header-top-padding: 0;

    @media (--screen-lt-md) {
        --header-top-padding: var(--space-32);
    }
}

.topContent {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.form {
    inline-size: 100%;
}

.illustration {
    margin-block-start: var(--space-64);
    inline-size: 100%;
    max-inline-size: 582px;

    @media (--screen-lt-md) {
        order: -1;
        align-self: flex-start;
        margin-block-start: 0;
    }

    img {
        inline-size: 100%;
        block-size: auto;
    }
}

.stepper {
    inline-size: 100%;
    max-inline-size: 400px;
    visibility: visible;
    margin-block-end: var(--space-48);

    > div {
        padding-block-start: 0;
        padding-block-end: 0;
    }

    @media (--screen-lt-sm) {
        display: none;
    }
}
.stepper > div {
    padding-inline-start: 0;
    padding-inline-end: 0;
}

.mobileStepper {
    padding: var(--space-64) 0 0;
    margin: 0 auto;
    inline-size: 100%;
    max-inline-size: 320px;
    display: none;

    @media (--screen-lt-sm) {
        display: flex;
    }
}

.hidden {
    visibility: hidden;
}
