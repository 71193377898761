.grid {
    inline-size: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(230px, 100%), 1fr));
    gap: var(--space-12);
    align-items: center;
}

.iconCard {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    gap: var(--gap, var(--space-16));

    @media (--screen-lt-md) {
        --gap: var(--space-12);
    }
}

.textContainer {
    p:last-child {
        color: var(--display-onlight-secondary);
    }
}

.link {
    outline: 0;

    &:hover {
        text-decoration: none;
    }

    &::after {
        position: absolute;
        content: '';
        inset: 0;
    }
}
