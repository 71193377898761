.stats {
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    align-items: center;

    overflow: hidden;
    position: relative;
    inline-size: 100%;

    @media (--screen-gt-xlg) {
        --article-link-display: none;
    }

    .static {
        display: flex;
        inline-size: 100%;
        gap: var(--space-32);
        justify-content: center;
        align-items: baseline;
        padding: 0 var(--space-24);
    }
}

.carousel {
    @media (--screen-gt-sm) {
        &::before,
        &::after {
            position: absolute;
            background: linear-gradient(270deg, #fefdfc 0%, rgba(254, 253, 252, 0) 100%);
            width: 100px;
            top: 0;
            bottom: 0;
            z-index: 1;
            content: '';
        }

        &::before {
            left: 0;
            transform: rotate(-180deg);
        }

        &::after {
            right: 0;
        }
    }

    .scroller {
        --duplicates: 3;
        --scroll-duration: 30s;
        --gap: var(--space-32);
        --offset: calc(var(--gap) / var(--duplicates));
        --move-initial: 0%;
        --move-final: calc(-1 * 100% / var(--duplicates) - var(--offset));

        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: var(--gap);
        inline-size: max-content;
        animation: marquee var(--scroll-duration) linear infinite;

        @keyframes marquee {
            0% {
                transform: translateX(var(--move-initial));
            }

            100% {
                transform: translateX(var(--move-final));
            }
        }

        @media (prefers-reduced-motion) {
            --scroll-duration: 180s;
        }
    }
}
