.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    align-items: center;

    .header {
        display: flex;
        justify-content: center;
    }
}
